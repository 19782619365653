.user-profile {
  &__header {
    margin: 16px 0 30px;
    &--avatar {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      margin-right: 16px;
    }
    &--username {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 7px;
    }
    &--active-icon {
      margin: 0 8px ;
    }
    &--last-active {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  &__content-wrapper {
    margin: 48px auto 16px;
    width: 60%;
  }
  &__general {
    &--block {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid var(--borderColor);
    }
    &--subtitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    &--socials {
      flex-wrap: wrap;
    }
    &--social {
      background-color: var(--grayColorOpacity05);
      border-radius: 50px;
      padding: 8px 16px 8px 8px;
      margin: 24px 16px 0 0;
      &-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        opacity: 0.5;
      }
      &-icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        opacity: 0.5;
      }
      &-check {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        opacity: 0.5;
      }
      &.is-connected {
        background-color: var(--greenColorOpacity10);
        .user-profile__general--social {
          &-name, &-icon, &-check {
            opacity: 1;
          }
        }
      }
    }
    &--roles {
      display: flex;
      flex-flow: column;
      margin-top: 15px;
    }
    &--check-wrapper {
      width: max-content;
    }
    &--check-label {
      font-size: 14px!important;
      line-height: 18px!important;
      letter-spacing: -0.266667px!important;
      font-family: "Inter", sans-serif!important;
      user-select: none;
    }
    &--balance {
      margin: 12px 0 -12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      &-label {
        font-size: 14px;
        line-height: 18px;
        margin-right: 36px;
      }
      &-value {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
      }
      &-amount-wrapper {
        width: 178px;
        margin: 32px 0;
      }
    }
    &--settings {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &--save-btn {
      display: flex;
      justify-content: center;
      padding-top: 8px;
      .default_button {
        min-width: 200px;
        height: 48px;
      }
    }
  }
  &__platforms {
    &--platform {
      border-bottom: 1px solid var(--borderColor);
      padding-bottom: 24px;
      margin-bottom: 24px;
      .default_button {
        width: 90px!important;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &-icon {
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      &-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      &-profile-id {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-right: 40px;
      }
    }
    &--fields {
      margin-top: 32px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 25px;
      grid-row-gap: 32px;
      .default_button {
        width: 132px!important;
        &_contained {
          margin-right: 16px;
        }
      }
    }
    &--disconnect {
      text-align: end;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--grayColor);
    }
  }
  &__affiliate {
    &--invitation-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid var(--borderColor);
      padding: 24px 0 32px;
      margin-bottom: 32px;
    }
    &--blogger-icon {
      margin-right: 12px;
      height: 36px;
      width: 36px;
      border-radius: 50%;
    }
    &--value-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    &--description-text {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    &--blogger-settings {
      border-bottom: 1px solid var(--borderColor);
      padding: 24px 0 32px;
      margin-bottom: 32px;
    }
    &--form-fields {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-column-gap: 25px;
    }
  }
  &__kyc {
    &--documents {
      width: 100%;
      padding: 32px 0 24px;
    }
    &--document {
      border-bottom: 1px solid var(--borderColor);
      padding-bottom: 28px;
      margin-bottom: 24px;
      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      &-image {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        margin-right: 12px;
        cursor: pointer;
      }
      &-link {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--blueColor);
      }
      &-link-icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
      &-delete {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--grayColor);
        margin-right: 32px;
      }
    }
  }
  &__skill-challenges {
    &--limit {
      border-bottom: 1px solid var(--borderColor);
      padding: 24px 0 32px;
      margin-bottom: 32px;
    }
    &--form-fields {
      display: flex;
      flex-flow: column;
      .input-field {
        margin-bottom: 24px;
        width: 128px;
      }
    }
    &--statistics {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 12px;
      border-bottom: 1px solid var(--borderColor);
      padding-bottom: 40px;
      margin: 24px 0 40px;
      &-row {
        display: flex;
        justify-content: space-between;
        width: 200px;
      }
      &-label {
        font-size: 14px;
        line-height: 18px;
      }
      &-value {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        &.white {
          color: var(--primaryColor);
        }
        &.gray {
          color: var(--grayColor);
        }
        &.green {
          color: var(--greenColor);
        }
      }
    }
  }
  &__disputes {
    margin: 48px 0 16px;
    width: 100%;
    &--lost-count {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.266667px;
      color: var(--grayColor);
      & > span {
        font-weight: 700;
      }
    }
    &--wrapper {
      margin: 24px 0 32px;
    }
    &--subtitle {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--grayColor);
      margin-bottom: 8px;
    }
    &--row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: 1px solid var(--borderColor);
    }
    &--col {
      &:first-of-type {
        width: 23%;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
      &:nth-of-type(3) {
        width: 30%;
      }
      &:last-of-type {
        width: 7%;
      }
    }
    &--id {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      margin-bottom: 2px;
      text-decoration: none;
      &.clickable {
        color: var(--blueColor);
      }
    }
    &--date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    &--result {
      border-radius: 30px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.5px;
      width: 57px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.win {
        background: var(--greenColorOpacity10);
        color: var(--greenColor);
      }
      &.loss {
        background: rgba(115, 122, 147, 0.1);
        color: var(--grayColor);
      }
      &.draw {
        background: var(--primaryColorOpacity10);
      }
    }
  }
}