a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{border:0;margin:0;padding:0;font-size:100%}
article,aside,details,figcaption,figure,footer,header,menu,nav,section{display:block}
b,strong{font-weight:bold}
img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}
li{display:list-item;list-style:none}
table{border-collapse:collapse;border-spacing:0}
th,td,caption{font-weight:normal;vertical-align:top;text-align:left}
q{quotes:none}
q:before,q:after{content:'';content:none}
sub,sup,small{font-size:75%}
sub,sup{line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}
sup{top:-0.5em}
svg{overflow:hidden}
*{padding:0;margin:0;border:0;outline:none}
.clear:after,.clear:before{
  clear:both;
  display:block;
  zoom:1;
  content:'';
}
section{display: flex}
h1,h2,h3,h4,h5,h6{font-weight: normal}

a, button{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
}