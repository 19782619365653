.message_popper {
  .message_tooltip {
    background-color: #29323C;
    font-size: 10px;
    word-break: break-word;
    font-family: "Inter", sans-serif;
  }
  .error_message_tooltip {
    background-color: #ff0000;
    font-size: 10px;
    font-family: "Inter", sans-serif;
  }
  &.match-info-tooltip {
    background-color: var(--additionalBackgroundColor)!important;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px 24px;
    margin-left: 11px;
    .message_tooltip {
      background-color: transparent;
      margin: 0;
    }
  }
}

body {
  .auth_tooltip_popper {
    top: 8px !important;
    left: 9px;
  }
}