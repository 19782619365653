.datetime-picker-wrapper{
  height: 52px;
  width: 100%;
 .datetime-picker {
   width: 100%;
   height: 100%;
   background-color: var(--additionalBackgroundColor);
   & > div {
     height: 100%;
     &:before, &:after {
       border-bottom: 2px solid var(--grayColor)!important;
     }
   }
 }
  input {
    font-family: 'Inter', sans-serif;
    color: var(--primaryColor);
    font-size: 14px;
    line-height: 18px;
    padding-left: 16px;
  }
}