.app-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 28px;
  background: var(--primaryBackgroundColor);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
  height: 50px;
  .logo-wrapper {
    display: flex;
    align-items: center;
    .gs-logo {
      margin-left: 30px;
      cursor: pointer;
    }
  }
  .logout {
    display: flex;
    align-items: center;
    .email {
      margin-right: 12px;
      font-size: 14px;
      line-height: 18px;
      color: var(--grayColor);
    }
  }
}
