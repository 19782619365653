html {
  --primaryColor: #FFFFFF;
  --primaryColorOpacity50: rgba(255, 255, 255, 0.5);
  --primaryColorOpacity05: rgba(255, 255, 255, 0.05);
  --primaryColorOpacity10: rgba(255, 255, 255, 0.1);
  --grayColor: #737A93;
  --grayColorOpacity05: rgba(115, 122, 147, 0.05);
  --redColor: #F32A2A;
  --greenColor: #2FC272;
  --greenColorOpacity30: rgba(47, 194, 114, 0.3);
  --greenColorOpacity10: rgba(47, 194, 114, 0.1);
  --blueColor: #57A1E6;
  --blueColorOpacity10: rgb(87, 161, 230, 0.1);
  --orangeColor: #F3BB48;
  --errorColor: #F54D4D;
  --errorColorOpacity30: rgba(245, 77, 77, 0.3);
  --errorColorOpacity10: rgba(245, 77, 77, 0.1);

  --primaryBackgroundColor: #0E1832;
  --secondaryBackgroundColor: #192036;
  --additionalBackgroundColor: #1f2d48;
  --additionalTwoBackgroundColor: #252B40;
  --pageBackground : #0C1427;
  --fieldsBackground : #19214D;
  --inputBackground : #1F2D48;

  --borderColor: #252A47;
}