.inactive-tournament-in-list {
  height: 70px;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  .first-block {
    width: 25%;
    .name {
      color: var(--primaryColor);
      letter-spacing: -0.5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
      width: 95%;
    }
    .start-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .second-block {
    width: 30%;
  }
  .third-block {
    width: 15%;
    .fee {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    .label {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .fourth-block {
    width: 15%;
    display: flex;
    align-items: center;
    .participants-icon {
      width: 36px;
      height: 36px;
    }
    .participants {
      margin-left: 16px;
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      height: 36px;
      .values-wrapper {
        display: flex;
        align-items: flex-start;
      }
      .value {
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin: 0 20px 2px 0;
        position: relative;
        min-width: 18px;
        .label {
          position: absolute;
          left: 0;
          bottom: -20px;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.5px;
          color: var(--primaryColor);
          text-transform: lowercase;
        }
      }
    }
  }
  .last-block {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .restore {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--greenColor);
      width: max-content;
      margin-right: 24px;
    }
  }
}

.restore-tournament-dialog {
  width: 100%;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
  }
  .description {
    font-size: 14px;
    line-height: 18px;
    color: var(--primaryColor);
    margin: 16px 0 32px;
  }
  .separator {
    width: 100%;
    height: 1px;
    background-color: var(--borderColor);
    margin: 32px 0;
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      margin: 0 8px;
      .default_button {
        width: 128px;
      }
    }
  }
}