.back-link {
  display: flex;
  align-items: center;
  color: var(--blueColor);
  font-weight: 500;
  font-size: 14px;
  width: max-content;
  cursor: pointer;
  .arrow-back {
    margin-right: 8px;
    height: 10px;
    width: 7px;
  }
}