.challenges-system {

  .tabs-wrapper {
    margin-top: 28px;
  }

  &__sub-tabs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--actions {
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
  }

  &__subtitle {
    margin: 32px 0 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--grayColor);
  }

  &__challenge-row {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 0;
    min-height: 64px;
    border-bottom: 1px solid var(--borderColor);

    &.active-challenge {
      background-color: var(--blueColorOpacity10);
      cursor: pointer;
    }

    &.header-row {
      min-height: unset;
    }

    .table-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }

    &--col {
      display: flex;
      align-items: center;

      &.header-col {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        user-select: none;
        padding: 12px 0;
        color: var(--grayColor);
      }

      &:first-of-type {
        width: 55%;
      }

      &:nth-of-type(2) {
        width: 25%;
      }

      &:last-of-type {
        width: 20%;
        justify-content: space-between;
      }
    }

    &--name {
      width: calc(90% - 48px);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      color: var(--blueColor);
    }

    &--drag-handle-wrapper {
      margin-right: 16px;
      width: 32px;
      height: 32px;

      & > img {
        width: 100%;
        height: 100%;
      }

      &.active-challenge {
        & > img {
          display: none;
        }
      }
    }

    &--status {
      padding: 9px 16px;
      border-radius: 22.5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;

      &.unpublished {
        background: rgba(115, 122, 147, 0.1);
        color: var(--grayColor);
      }

      &.published {
        background: rgba(87, 161, 230, 0.1);
        color: var(--blueColor);
      }

      &.active {
        background: var(--greenColorOpacity10);
        color: var(--greenColor);
      }
    }

    &--checkmark {
      width: 9px;
      margin-right: 7px;
    }
  }
}