.challenge-reward {
  &__btn-content {
    display: flex;
    align-items: center;
    & > img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
  &__dialog {
    width: 100%;
    &--title {
      margin-bottom: 32px;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
    &--description {
      margin: 32px 0 40px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--grayColor);
    }
    &--form-fields {
      width: 100%;
    }
    &--several-fields {
      margin-top: 24px;
      display: flex;
      align-items: center;
      column-gap: 25px;
    }
  }
}