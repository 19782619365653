.tournament-disputes {
  &__wrapper {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 32px;
    .tabs-wrapper {
      margin: 28px 0;
    }
    .pagination-wrapper {
      margin-top: 32px;
    }
  }
  &__dispute {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--borderColor);
  }
  &__column {
    &:first-of-type {
      width: 20%;
    }
    &:nth-of-type(2) {
      width: 35%;
    }
    &:last-of-type {
      width: 45%;
      display: flex;
      align-items: center;
      .vs-separator {
        margin: auto 16px;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.5px;
        color: var(--blueColor);
        background: rgba(87, 161, 230, 0.1);
        border-radius: 30px;
        font-weight: 700;
        padding: 0 5px;
      }
    }
    &--link {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      color: var(--blueColor);
      margin-bottom: 2px;
      display: block;
    }
    &--date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
}