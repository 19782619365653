.upcoming-tournament-in-list {
  width: 100%;
  height: 70px;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  .first-block {
    width: 20%;
    .name {
      color: var(--blueColor);
      letter-spacing: -0.5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      width: 95%;
    }
    .start-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .second-block {
    width: 25%;
  }
  .third-block {
    width: 10%;
    .fee {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    .label {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .fourth-block {
    width: 25%;
    display: flex;
    align-items: center;
    .participants-icon {
      width: 36px;
      height: 36px;
    }
    .participants {
      margin-left: 16px;
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      height: 36px;
      .values-wrapper {
        display: flex;
        align-items: flex-start;
      }
      .value {
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin: 0 20px 2px 0;
        position: relative;
        min-width: 18px;
        &.inactive {
          pointer-events: none;
          color: var(--grayColor);
        }
        &.clickable {
          color: var(--blueColor);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .label {
          position: absolute;
          left: 0;
          bottom: -20px;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.5px;
          color: var(--primaryColor);
          text-transform: lowercase;
        }
      }
    }
  }
  .fifth-block {
    width: 15%;
    .tournament-status {
      width: 120px;
      background: rgba(87, 161, 230, 0.1);
      border-radius: 22px;
      padding: 12px 0;
      color: var(--blueColor);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      .check-icon {
        margin-right: 5px;
        width: 11px;
      }
    }
  }
  .last-block {
    width: 5%;
  }
}