.users-list-dialog {
  width: 100%;
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-right: 8px;
    }
    .number {
      font-size: 24px;
      line-height: 32px;
      color: var(--primaryColorOpacity50);
    }
    .add-random {
      margin-left: auto;
      color: var(--greenColor);
      font-weight: 500;
      font-size: 14px;
    }
  }
  .users-list {
    max-height: 314px;
    width: 100%;
    .user {
      width: calc(100% - 12px);
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--borderColor);
      &:last-of-type {
        border-bottom: none;
      }
      .info-block {
        display: flex;
        align-items: center;
        .avatar {
          border-radius: 50%;
          width: 36px;
          height: 36px;
          object-fit: cover;
          margin-right: 12px;
        }
        .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .actions-block {
        button {
          margin-right: -10px;
        }
      }
    }
  }
  .users-actions {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      margin: 0 8px;
      .default_button {
        height: 40px;
        width: 128px;
      }
    }
  }
}