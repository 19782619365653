.search-by {
  &__wrapper {
    position: relative;
    width: 436px;
    height: 48px ;
  }
  &__field {
    width: calc(100% - 116px);
    height: 100%;
    background: var(--inputBackground);
    color: var(--primaryColor);
    border-radius: 4px;
    padding: 0 100px 0 16px;
    font-size: 14px;
    font-weight: 500;
    &::placeholder {
      font-weight: 400;
      color: var(--primaryColorOpacity50);
    }
  }
  &__clear {
    &--wrapper {
      position: absolute;
      right: 48px;
      top: 12px;
      border-right: 1px solid var(--primaryColorOpacity10);
      padding: 0 16px;
      height: 24px;
    }
    &--icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  &__search-icon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}