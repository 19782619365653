.tournaments-wrapper {
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tabs-wrapper {
    margin: 28px 0;
  }
  .pagination-wrapper {
    margin-top: 32px;
  }
}