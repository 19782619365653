.no-items {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--grayColor);
  background: var(--grayColorOpacity05);
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
}
