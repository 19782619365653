.popper-wrapper {
  background-color: #ffffff;
  border-radius: 6px;
  color: var(--primaryBackgroundColor);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 15px;
  padding: 8px;
  max-width: 200px;
  min-width: 80px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 20px;
    top: 0;
    z-index: -99;
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    margin-top: -7px;
    -webkit-transform: rotate(40deg) skewX(-20deg) skewY(-10deg);
    -moz-transform: rotate(40deg) skewX(-20deg) skewY(-10deg);
    -ms-transform: rotate(40deg) skewX(-20deg) skewY(-10deg);
    -o-transform: rotate(40deg) skewX(-20deg) skewY(-10deg);
    transform: rotate(40deg) skewX(-20deg) skewY(-10deg);
  }
  &.first-place-prize-popper {
    margin-top: 10px;
  }
}