.challenges-fields {
  &__buttons-wrapper {
    padding-top: 40px;
    margin: 40px 0 8px;
    border-top: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      margin: 0 8px;
      .default_button {
        height: 48px;
        width: 200px;
      }
    }
  }
}