.tournament-inner-header {
  display: flex;
  flex-flow: column;
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 48px;
    h1 {
      display: flex;
      align-items: center;
    }
    .start-date-and-status {
      display: flex;
      align-items: center;
      margin-top: 4px;
      .start-date {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.5px;
      }
      .tournament-status {
        color: var(--blueColor);
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .check-icon {
          margin-right: 5px;
          width: 11px;
        }
      }
    }
    .open-icon {
      margin-right: 6px;
    }
    .default_button {
      margin-right: 32px;
    }
  }
  .upcoming-tournament-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .game-info, .users-info {
      width: 30%;
    }
    .users-info {
      display: flex;
      align-items: flex-start;
      .value {
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin: 0 20px 2px 0;
        position: relative;
        min-width: 18px;
        &.inactive {
          pointer-events: none;
          color: var(--grayColor);
        }
        &.clickable {
          color: var(--blueColor);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .label {
          position: absolute;
          left: 0;
          bottom: -20px;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.5px;
          color: var(--primaryColor);
          text-transform: lowercase;
        }
      }
    }
    .participants-icon {
      margin-right: 16px;
    }
    .finance-info {
      width: 40%;
      display: flex;
      align-items: flex-start;
      height: 100%;
      .line-separator {
        background-color: var(--borderColor);
        width: 1px;
        height: 100%;
        margin: 0 16px;
      }
      .value {
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 2px;
        &.inactive {
          pointer-events: none;
          color: var(--grayColor);
        }
        &.clickable {
          color: var(--blueColor);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.5px;
        font-weight: 300;
        text-transform: lowercase;
      }
    }
  }
  .current-tournament-info {
    display: flex;
    align-items: center;
    width: 100%;
    .game-info, .participants-and-disputes, .finance-info, .win-amount-info {
      height: 100%;
      display: flex;
      align-items: flex-start;
    }
    .game-info, .participants-and-disputes {
      width: 30%;
    }
    .finance-info, .win-amount-info {
      width: 20%;
    }
    .participants-icon {
      margin-right: 16px;
    }
    .disputes-number {
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 36px;
      height: 36px;
      background: rgba(87, 161, 230, 0.1);
      border-radius: 22.5px;
      .disputes-icon {
        width: 22px;
        height: 22px;
      }
      .unread {
        position: absolute;
        border-radius: 50%;
        border: 2px solid var(--secondaryBackgroundColor);
        background-color: var(--greenColor);
        width: 8px;
        height: 8px;
        top: -1px;
        right: -1px;
        &.unresolved {
          background-color: var(--orangeColor);
        }
      }
    }
    .line-separator {
      background-color: var(--borderColor);
      width: 1px;
      height: 100%;
      margin: 0 16px;
    }
    .value {
      letter-spacing: -0.5px;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 2px;
      &.clickable {
        color: var(--blueColor);
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      &.green {
        color: var(--greenColor);
        font-weight: 700;
      }
    }
    .label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
      font-weight: 300;
      text-transform: lowercase;
    }
  }
}

.not-published {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  padding: 48px 32px;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px;
  }
  .description {
    display: flex;
    align-items: center;
    color: var(--grayColor);
    font-size: 14px;
    .clickable {
      color: var(--blueColor);
      font-weight: 600;
    }
  }
}

.disputes-dialog {
  width: 100%;
  &__title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  &__number {
    font-size: 24px;
    line-height: 32px;
    opacity: 0.5;
    margin-left: 8px;
    font-weight: 400;
  }
  &__disputes-wrapper {
    width: 100%;
    margin-top: 40px;
    max-height: 350px;
  }
  &__dispute {
    box-sizing: border-box;
    width: 100%;
    background: var(--secondaryBackgroundColor);
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    &--header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    &--info {
      display: flex;
      align-items: center;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    &--date {
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid var(--borderColor);
    }
    &--users-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .go-to-btn {
        width: 62px;
        height: 62px;
      }
    }
    &--users {
      width: calc(100% - 62px);
    }
    &--separator {
      width: 100%;
      height: 1px;
      background-color: var(--borderColor);
      margin: 8px 0;
    }
    &--user {
      display: flex;
      align-items: center;
    }
    &--user-avatar {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      border-radius: 50%;
    }
    &--user-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      height: 26px;
    }
    &--winner {
      font-weight: 700;
      font-size: 12px;
      color: var(--greenColor);
      background: var(--greenColorOpacity10);
      border-radius: 30px;
      margin-left: 8px;
      padding: 3px 8px;
    }
  }
}