.confirmation-dialog {
  width: 100%;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    margin-bottom: 16px;
    &.initial-text {
      text-transform: unset;
    }
  }
  .description {
    font-size: 14px;
    line-height: 18px;
    color: var(--primaryColor);
  }
  .separator {
    width: 100%;
    height: 1px;
    background-color: var(--borderColor);
    margin: 32px 0;
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      margin: 0 8px;
      .default_button {
        width: 128px;
      }
    }
  }
}