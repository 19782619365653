.auth_container {
  width: 100%;
  min-height: 768px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  form {
    width: 572px;
    box-sizing: border-box;
    padding: 80px 128px 48px;
    text-align: center;
    background: var(--primaryBackgroundColor);
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    position: relative;
    .custom_input_wrapper {
        margin-bottom: 20px;
    }
    .default_button_wrapper {
      margin: 28px 0 48px;
    }
    .auth_error {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 48px;
      left: calc(50% - 109px);
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--redColor);
      img {
        margin-right: 12px;
      }
    }
  }
}

.auth_block_head{
  font-family: "Inter",sans-serif;
  font-size: 38px;
  line-height: 46px;
  font-weight: 700;
}

.auth_block_descriptions{
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: var(--grayColor);
  margin: 24px 0 48px;
}