.users {
  &__wrapper {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 32px;
    position: relative;
    &.with-mb {
      margin-bottom: 88px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__list {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-flow: column;
  }
  &__user-row {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 0;
    min-height: 64px;
    &.header-row {
      min-height: unset;
      padding-right: 5%;
      border-bottom: 1px solid var(--borderColor);
    }
    &--avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    &--username {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
    }
    &--is-active {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    &--user-name {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
       opacity: 0.66;
    }
    .is_blocked {
      color: var(--grayColor);
    }
    .table-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    &--col {
      &.header-col {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        user-select: none;
        padding: 12px 0;
        color: var(--grayColor);
        &.active-sort {
          color: var(--primaryColor);
        }
      }
      &:first-of-type {
        width: 5%;
        user-select: none;
        img {
          cursor: pointer;
        }
      }
      &:nth-of-type(2) {
        width: 10%;
        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: -0.5px;
          color: var(--blueColor);
        }
      }
      &:nth-of-type(3) {
        width: 25%;
        display: flex;
        align-items: center;
      }
      &:nth-of-type(4) {
        width: 28%;
      }
      &:nth-of-type(5) {
        width: 17%;
      }
      &:nth-of-type(6) {
        width: 10%;
      }
      &:last-of-type {
        width: 5%;
      }
    }
  }
  &__bulk-panel {
    position: fixed;
    bottom: -88px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 88px;
    background: var(--inputBackground);
    box-shadow: 0px -8px 32px rgba(13, 21, 37, 0.5);
    &.is-open {
      bottom: 0;
    }
    &--content {
      margin-left: 72px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 33%;
    }
    &--checked {
      margin-right: 12px;
      height: 24px;
      width: 24px;
    }
    &--selected {
      font-weight: 500;
      font-size: 14px;
      margin-right: 24px;
    }
    &--deselect {
      font-weight: 500;
      font-size: 14px;
      margin-right: 24px;
      color: var(--grayColor);
    }
    &--delete {
      display: flex;
      align-items: center;
    }
    &--delete-icon {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
    &--delete-text {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.5px;
    }
  }
  &__more-info {
    display: flex;
    flex-flow: column;
    background: var(--additionalTwoBackgroundColor);
    border-radius: 8px;
    width: 100%;
    padding: 32px 48px;
    &--title {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    &--separator {
      background-color: var(--primaryColorOpacity10);
      width: 100%;
      height: 1px;
      margin: 20px 0 32px;
    }
    &--cols-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &--col {
      width: 25%;
      display: flex;
      flex-flow: column;
    }
    &--row {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.266667px;
      margin-bottom: 12px;
      .platform {
        margin-left: 26px;
        margin-top: -8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        &.gray {
          color: var(--grayColor);
          &:first-letter {
            text-transform: uppercase;
          }
        }
        &.green {
          color: var(--greenColor);
        }
      }
      .platform-icon-and-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.266667px;
        flex-direction: row-reverse;
        .platform-icon {
          margin-left: 6px;
          width: 14px;
          height: 14px;
        }
      }
    }
    &--value-in-row {
      margin-left: auto;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      &.white {
        color: var(--primaryColor);
      }
      &.gray {
        color: var(--grayColor);
      }
      &.green {
        color: var(--greenColor);
      }
    }
    &--active {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
    &--subtitle {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--grayColor);
      margin-bottom: 24px;
    }
  }
}

.add-user {
  .back-link {
    margin-left: 32px;
  }
  h1 {
    margin: 16px 0 48px 32px;
  }
}