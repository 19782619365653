.tournament-dispute-inner {
  &__header-wrapper {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 32px 96px 40px 32px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    &--info-wrapper {
      margin-top: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--number {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 4px ;
    }
    &--date-and-status {
      display: flex;
      align-items: center;
    }
    &--date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    &--dot-separator {
      margin: auto 12px;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }
    &--tournament-and-game {
      display: flex;
      align-items: center;
    }
    &--tournament-info {
      margin-right: 32px;
      padding-right: 32px;
      border-right: 1px solid var(--borderColor);
    }
    &--tournament-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      margin-bottom: 2px;
    }
    &--tournament-round {
      display: flex;
      align-items: center;
      color: var(--blueColor);
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
      .round-icon {
        height: 12px;
        width: 12px;
        margin-right: 4px;
      }
    }
  }
  &__content-wrapper {
    display: flex;
    justify-content: space-between;
    &--left-column {
      width: 360px;
    }
    &--right-column {
      width: calc(100% - 380px);
    }
  }
  &__participants, &__screenshots {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 24px 32px 32px;
    &--title {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    &--separator {
      margin: 24px 0;
      background-color: var(--borderColor);
      height: 1px;
      width: 100%;
    }
    &--user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--user-icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 12px;
    }
    &--resolved {
      background-color: var(--greenColorOpacity10);
      height: 40px;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      margin: 0 auto;
      width: max-content;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--greenColor);
    }
  }
  &__participants {
    margin-bottom: 20px;
    &--subtitle {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: var(--grayColor);
      margin-bottom: 12px;
    }
    &--user-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    &--username {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
    &--win {
      background: var(--greenColorOpacity10);
      border-radius: 30px;
      letter-spacing: -0.5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: var(--greenColor);
      padding: 3px 12px;
      text-transform: uppercase;
    }
    &--winner {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: var(--greenColor);
    }
    .default_button_wrapper {
      text-align: center;
    }
  }
  &__screenshots {
    &--user-icon {
      margin-right: 8px;
    }
    &--user-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    &--row {
      display: flex;
      align-items: center;
    }
    &--screenshot {
      margin-right: 32px;
      width: 64px;
      height: 64px;
      object-fit: cover;
      &.clickable {
        cursor: pointer;
      }
    }
  }
  &__chat {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
  }
}

.choose-winner-dialog {
  .description {
    margin-bottom: 32px;
  }
  &__opponent-row {
    margin-top: 12px;
    width: 100%;
    display: flex;
    background: #1F2742;
    border-radius: 8px;
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
    padding: 16px;
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      right: 26px;
      top: calc(50% - 8px);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z' fill='%232FC272'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5608 5.1283C11.6664 5.23899 11.772 5.34969 11.8776 5.46038C12.0408 5.63145 12.0408 5.91321 11.8776 6.08428C10.4374 7.59371 8.9973 9.10315 7.54755 10.6226C7.06751 11.1258 6.9715 11.1258 6.49145 10.6226C5.70417 9.79748 4.91689 8.97233 4.12961 8.14717C3.9568 7.96604 3.9568 7.68428 4.12961 7.51321C4.22562 7.40252 4.33123 7.30189 4.43684 7.1912C4.60006 7.02013 4.86889 7.02013 5.0417 7.1912C5.69457 7.87547 6.35704 8.56981 7.0195 9.26415C8.33483 7.88553 9.65017 6.50692 10.9655 5.1283C11.1287 4.95723 11.3975 4.95723 11.5608 5.1283Z' fill='white'/%3E%3C/svg%3E%0A");
      display: none;
    }
    &.active {
      border: 2px solid #2FC272;
      &:after {
        display: unset;
      }
    }
  }
}