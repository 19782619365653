.completed-tournament-in-list {
  height: 70px;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .first-block {
    width: 25%;
    .name {
      color: var(--blueColor);
      letter-spacing: -0.5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      width: 95%;
    }
    .start-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .second-block {
    padding-left: 10px;
    width: 30%;
  }
  .third-block {
    width: 15%;
    .fee {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    .label {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .fourth-block {
    width: 15%;
    display: flex;
    align-items: center;
    .participants-icon {
      width: 36px;
      height: 36px;
    }
    .participants {
      margin-left: 16px;
      .value {
        cursor: pointer;
        color: var(--blueColor);
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 2px;
        &:hover {
          text-decoration: underline;
        }
      }
      .label {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.5px;
        text-transform: lowercase;
      }
    }
  }
  .last-block {
    width: 15%;
    .top-places-control {
      color: var(--blueColor);
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 8px;
    }
  }
}

.top-places-wrapper {
  background: var(--additionalTwoBackgroundColor);
  border-radius: 8px;
  width: 100%;
  padding: 8px 25%;
  .place {
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .items-center {
      width: 30%;
      img {
        margin-right: 8px;
      }
    }
    .user {
      width: 60%;
      font-weight: 700;
      display: flex;
      align-items: center;
      .user-icon {
        margin-right: 8px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
    .prize-amount {
      padding: 6px 17px;
      background: rgba(42, 236, 131, 0.05);
      border-radius: 100px;
      font-size: 18px;
      font-weight: 700;
      color: var(--greenColor);
    }
  }
}