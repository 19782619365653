.edit-tournament {
  .back-link {
    margin-left: 32px;
  }
  h1 {
    margin: 16px 0 48px 32px;
  }
  .loader_block {
    margin-left: 32px;
  }
}