@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400');

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  src: url("../fonts/Inter-Light-BETA.woff2") format("woff2"),
  url("../fonts/Inter-Light-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
  url("../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
  url("../fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url("../fonts/Inter-SemiBold.woff2") format("woff2"),
  url("../fonts/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
  url("../fonts/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  src: url("../fonts/Inter-ExtraBold.woff2") format("woff2"),
  url("../fonts/Inter-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  src: url("../fonts/Inter-Black.woff2") format("woff2"),
  url("../fonts/Inter-Black.woff") format("woff");
}

@import "reset";
@import "base";
@import "variables";