.default_dialog_root {
  .paper_custom_dialog{
    border-radius: 6px;
    min-width: 500px;
    max-width: 500px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.dialog_wrapper {
  padding: 48px 64px;
  display: flex;
  position: relative;
  background-color: var(--secondaryBackgroundColor);
  max-height: 500px;
  overflow: auto;
  color: var(--primaryColor);
}
