.edit-challenge {
  overflow: visible;
  .back-link {
    margin: 0 0 16px 32px;
  }
  h1 {
    margin: 0 0 48px 32px;
  }
  .loader_block {
    margin-left: 32px;
  }
}