body{
  overflow: auto;
  box-sizing: border-box;
  background: var(--pageBackground);
  font-family: "Inter", sans-serif;
  color: var(--primaryColor);
}

.page-with-panel {
  display: flex;
  box-sizing: border-box;
  .page-content-wrapper, .users__bulk-panel {
    width: calc(100% - 275px);
  }
  &.panel-is-hidden {
    .page-content-wrapper, .users__bulk-panel {
      width: calc(100% - 104px);
    }
  }
}

.page-content-wrapper{
  min-width: 1085px;
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  padding: 40px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.default-content-wrapper {
  background-color: var(--secondaryBackgroundColor);
  border-radius: 8px;
  padding: 32px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.text-hover {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  cursor: pointer;
  &:hover{
    opacity: 0.5;
  }
}

.card-hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -ms-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
  &:hover{
    margin-top: -3px;
    margin-bottom: 3px;
  }
}

.no-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Toastify__toast {
  font-family: "Inter", sans-serif!important;
  padding: 6px 16px!important;
  font-size: 0.875rem!important;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)!important;
  border-radius: 4px!important;
  &--error {
    background: var(--redColor)!important;
  }
  &--success {
    background: var(--greenColor)!important;
  }
}


.Toastify__toast-container {
  width: 450px!important;
}

.platform-icon-and-name {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: -0.5px;
  font-family: "Inter", sans-serif;
  .platform-icon {
    height: 10px;
    width: 12px;
    margin-right: 4px;
    &.supercell {
      height: 14px;
      width: 14px;
    }
  }
}

.game-info-wrapper {
  display: flex;
  align-items: center;
  .game-icon {
    width: 38px;
    height: 38px;
    border-radius: 3px;
  }
  .info {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 12px;
    .name {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.5px;
    }
    .lol-region {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
}

.user-info-wrapper {
  display: flex;
  align-items: center;
  .user-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
  .info {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 8px;
    .name {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 20px;
      margin-bottom: 2px;
    }
    .username {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
      .dispute-winner {
        margin-left: 4px;
        color: var(--greenColor);
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: 0 8px 0 4px;
          background-color: var(--greenColor);
        }
      }
    }
    .user-id {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      color: var(--blueColor);
    }
    .dot-separator {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--grayColor);
    }
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.with-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: var(--primaryBackgroundColor);
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--additionalBackgroundColor);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: var(--additionalBackgroundColor);
  }
}

.default-popover {
  background-color: transparent!important;
}

.app-menu {
  background: var(--additionalTwoBackgroundColor);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 8px 0;
  position: relative;
  z-index: 999;
  hr {
    background-color: var(--primaryColorOpacity10);
    height: 1px;
    width: calc(100% - 64px);
    margin: 8px auto;
  }
  &.tournaments-actions, &.user-actions, &.challenge-actions {
    width: 200px;
    a, button {
      padding: 12px 32px;
      & > img {
        width: 24px;
        height: 24px;
        &.start-icon {
          width: 14px;
          margin-right: 4px;
        }
      }
    }
  }
  a, button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--primaryColor);
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.5px;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      background-color: var(--primaryColorOpacity10);
    }
  }
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-bottom-48 {
  margin-bottom: 48px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.text-capitalize {
  text-transform: capitalize;
}

.lower-case {
  text-transform: lowercase;
}

.cursor-pointer {
  cursor: pointer;
}

.dot-separator {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin: 0 10px;
}

.items-center {
  display: flex;
  align-items: center;
}

.accordion-root {
  box-sizing: border-box;
  width: 100%;
  background-color: transparent!important;
  color: var(--primaryColor)!important;
  border-bottom: 1px solid var(--borderColor);
  .MuiAccordionSummary-content {
    box-sizing: border-box;
    width: 95%;
  }
  &:before {
    display: none;
  }
  .MuiAccordionDetails-root {
    padding: 0 0 16px!important;
  }
  .accordion-panel-root {
    width: 100%;
    box-sizing: border-box;
    padding-left: 0!important;
    & > div {
      margin: 0!important;
      padding: 0!important;
    }
  }
  margin: 0!important;
  min-height: unset!important;
  box-shadow: none!important;
  .accordion-panel-root {
    min-height: unset!important;
  }
  .accordion-expand-icon {
    width: 60px;
    height: 60px;
  }
}

.line-separator {
  height: 1px;
  width: 100%;
  background-color: var(--primaryColorOpacity10);
}

.dispute-status {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  .marker {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    border-radius: 50%;
  }
  &.pending {
    color: var(--orangeColor);
    .marker {
      background-color: var(--orangeColor);
    }
  }
  &.solved {
    color: var(--greenColor);
    .marker {
      background-color: var(--greenColor);
    }
  }
}

.mr-12 {
  margin-right: 12px;
}

.mr-32 {
  margin-right: 32px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-auto {
  margin: auto;
}

.ml-auto {
  margin: auto;
}

.ml-12 {
  margin-left: 12px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-24 {
  margin-top: 24px;
}

.pl-32 {
  padding-left: 32px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.with-transition {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.with-transition-100ms {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.sort-arrow {
  margin-left: 4px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid var(--grayColor);
  &.active {
    border-top: 3px solid var(--primaryColor);
  }
  &.active-rotated {
    border-top: 3px solid var(--primaryColor);
    transform: rotate(180deg);
  }
}

.platform-username-and-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.5px;
  opacity: 0.5;
  img {
    margin-left: 2px;
    width: 12px;
  }
}

.fields-wrapper {
  margin-left: 32px;
  width: 65%;
  min-width: 746px;
  .fields-block {
    display: flex;
    .fields {
      width: calc(100% - 244px);
      padding: 16px 0;
    }
  }
}

.fields-block {
  width: 100%;
  .block-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    width: 180px;
    border-right: 1px solid var(--grayColor);
    margin-right: 64px;
    padding-top: 16px;
  }
  .fields {
    .several-fields {
      display: flex;
      align-items: center;
      column-gap: 25px;
      .preview-image {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin-right: 24px;
      }
      .profile-photo {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
      .photo-btns {
        margin-top: 8px;
        display: flex;
        align-items: center;
        .change-btn, .delete-btn {
          color: var(--blueColor);
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
        .delete-btn {
          color: var(--grayColor);
          margin-left: 16px;
        }
      }
    }
    .input-with-info {
      width: 100%;
      position: relative;
      .info {
        position: absolute;
        bottom: -24px;
        left: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
        .value {
          font-weight: 600;
        }
      }
    }
  }
}

.radio-buttons {
  display: flex;
  align-items: center;
  column-gap: 32px;
  .radio-field {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.25px;
    & > img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
}

.input-file {
  display: none;
}

.platform-status {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  & > img {
    width: 16px;
    height: 16px;
    margin: 0 4px 2px 0;
  }
  &.verified, &.verifying {
    color: var(--greenColor);
  }
  &.not_connected {
    color: var(--grayColor);
  }
  &.error {
    color: var(--errorColor);
  }
}

.search-and-filters {
    margin: 32px 0 40px;
    display: flex;
    align-items: center;
    .default_button_wrapper {
      margin: 0 16px 0 32px;
    }
    &__filters-number {
      background: rgba(87, 161, 230, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--blueColor);
      font-weight: 700;
      font-size: 14px;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      margin-right: -16px;
    }
}

.filters {
  position: fixed;
  top: 0;
  right: -480px;
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 480px;
  box-sizing: border-box;
  background: var(--secondaryBackgroundColor);
  box-shadow: -16px 0px 64px rgba(0, 0, 0, 0.25);
  padding: 48px 48px 48px 80px;
  &.is-open {
    right: 0;
  }
  &--close-wrapper {
    background: var(--additionalTwoBackgroundColor);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -32px 38px auto;
  }
  &--title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &--container {
    padding-right: 32px;
  }
  &--wrapper {
    margin-bottom: 32px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--borderColor);
  }
  &--options {
    width: 100%;
    &:first-of-type {
      border-right: 1px solid var(--borderColor);
    }
    &.grid-2-col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;
      .filters--option {
        margin: 6px 0;
        &:last-of-type {
          margin-bottom: 12px!important;
        }
      }
    }
    &.with-scrollbar {
      max-height: 120px;
      margin-bottom: 32px;
      .filters--option {
        &:last-of-type {
          margin-bottom: 0!important;
        }
      }
    }
  }
  &--option {
    margin: 12px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.25px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    &:last-of-type {
      margin-bottom: 32px;
    }
  }
  &--btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    .default_button_wrapper {
      .default_button {
        height: 48px;
        width: 180px!important;
      }
    }
  }
  &--reset {
    font-weight: 500;
    font-size: 14px;
    color: var(--grayColor);
  }
}

.euro {
  color: var(--primaryColor)!important;
}

.free {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: var(--greenColor);
}