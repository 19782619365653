.current-tournament-in-list {
  height: 70px;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  .first-block {
    width: 25%;
    .name {
      color: var(--blueColor);
      letter-spacing: -0.5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      width: 95%;
    }
    .start-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .second-block {
    width: 30%;
  }
  .third-block {
    width: 15%;
    .fee {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    .label {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
    }
  }
  .fourth-block {
    width: 15%;
    display: flex;
    align-items: center;
    .participants-icon {
      width: 36px;
      height: 36px;
    }
    .participants {
      margin-left: 16px;
      .value {
        cursor: pointer;
        color: var(--blueColor);
        letter-spacing: -0.5px;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 2px;
        &:hover {
          text-decoration: underline;
        }
      }
      .label {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.5px;
        text-transform: lowercase;
      }
    }
  }
  .last-block {
    width: 15%;
    .state {
      background: rgba(87, 161, 230, 0.1);
      border-radius: 22px;
      width: max-content;
      padding: 12px;
      color: var(--blueColor);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      .round-icon {
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
    }
  }
}