.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loader_container {
  position: absolute;
  margin: 0 auto;
  top: 170px;
}

.loading {
  position: absolute;
  top: 80px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 24px;
  &::after {
    content: '.';
    animation: dots 0.5s steps(5, end) infinite;
  }
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;}
}