.custom_input_wrapper {
  width: 100%;
  max-width: 380px;
  .custom_input_label {
    font-family: 'Inter', sans-serif;
    color: rgba(255,255,255,0.72);
    font-size: 14px;
    opacity: 0.5;
    transform: translate(22px, 20px);
    &.custom_input_label_focused {
      color: rgba(255,255,255,0.72);
      transform: translate(22px, 10px) scale(0.75);
    }
    &.custom_input_label_active {
      transform: translate(22px, 10px) scale(0.75);
    }
    &.custom_input_label_error {
      color: #f44336;
    }
    &.custom_input_label_disabled {
      color: rgba(255,255,255,0.72);
    }
  }
  .custom_input {
    font-family: 'Inter', sans-serif;
    color: rgba(255,255,255,0.72);
    font-size: 14px;
    border-radius: 0;
    background: var(--fieldsBackground)!important;
    &:hover {
      &:before {
        border-bottom: 2px solid rgba(255,255,255,0.6) !important;
      }
    }
    &:before {
      border-bottom: 2px solid rgba(255,255,255,0.35);
    }
    input {
      padding: 26px 22px 9px;
      &:disabled {
        color: rgba(255,255,255,0.72);
      }
    }
    &.custom_input_focused {
      &:after {
        border-bottom: 2px solid var(--primaryColor);
      }
    }
    &.custom_input_error {
      &:after {
        border-bottom: 2px solid #f44336;
      }
    }
  }
  .custom_input_adorned {
    div {
      svg {
        fill: #f44336;
        cursor: pointer;
      }
    }
  }
  .custom_input_multiline {
    padding: 27px 22px 10px
  }
  .field_clear_btn {
    padding: 2px;
    img {
      width: 12px;
    }
  }
}