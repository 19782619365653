.registrations {
  &__wrapper {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 32px;
    position: relative;
  }
  &__date-pickers {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin: 32px 0 48px;
    .datetime-picker-wrapper {
      max-width: 160px;
      .datetime-picker {
        border-radius: 4px;
        & > div:after, & > div:before  {
          border-bottom: none!important;
        }
      }
    }
  }
  &__chart-wrapper {
    margin-top: 12px;
    background: rgba(#737A93, 0.05);
    border-radius: 8px;
    width: 100%;
    padding: 24px 32px;
    box-sizing: border-box;
    position: relative;
    h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #737A93;
    }
    &--pie-total {
      position: absolute;
      top: 120px;
      left: 59px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      row-gap: 4px;
      width: 128px;
      &-value {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.25px;
      }
      &-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--periods {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
    &--capsule {
      border-radius: 100px;
      padding: 8px 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      transition: 0.2s linear all;
      background: transparent;
      &.active {
        background: #F32A2A;
      }
    }
  }
}
