.branch-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 1000px;
  .branch-level {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    &:first-of-type {
      .branch-match {
        &:before {
          display: none;
        }
      }
    }
    &:last-of-type {
      .branch-match {
        &:after {
          display: none;
        }
      }
    }
  }
  .branch-match {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    position: relative;
    overflow: hidden;
    &:nth-child(1n) {
      &:before {
        content: "";
        position: absolute;
        top: calc(50% + 6.5px);
        left: 0;
        height: 2px;
        width: 12.5%;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    &:nth-child(2n - 1) {
      &:after {
        content: "";
        position: absolute;
        top: calc(50% + 6.5px);
        right: 0;
        height: 100%;
        width: 12%;
        border-top: 2px solid rgba(255, 255, 255, 0.3);
        border-right: 2px solid rgba(255, 255, 255, 0.3);
      }
    }
    &:nth-child(2n) {
      &:after {
        content: "";
        position: absolute;
        bottom: calc(50% - 8.5px);
        right: 0;
        height: 100%;
        width: 12%;
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
        border-right: 2px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
  .match-info {
    position: absolute;
    background-color: var(--additionalBackgroundColor);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--secondaryBackgroundColor);
    border-radius: 50%;
    right: calc(12% - 18px);
    top: calc(50% - 14px);
    z-index: 1;
    .info-icon {
      cursor: pointer;
      width: 22px;
      height: 22px;
    }
    .resolved-dispute-in-match, .unresolved-dispute-in-match {
      position: absolute;
      width: 6px;
      height: 6px;
      border: 2px solid var(--secondaryBackgroundColor);
      border-radius: 50%;
      top: -7px;
      right: -7px;
    }
    .resolved-dispute-in-match {
      background-color: var(--greenColor);
    }
    .unresolved-dispute-in-match {
      background-color: var(--orangeColor);
    }
  }
  .branch-cell {
    margin: 1px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
    height: 48px;
    background-color: var(--additionalBackgroundColor);
    position: relative;

  }
  .participant-in-branch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px 8px 8px;
    width: 100%;
    box-sizing: border-box;
    &.cell-with-winner {
      background-color: #247154;
      .participant-username {
        font-weight: 700;
        color: #A3F6C9;
      }
    }
    &:hover {
      .participant-actions {
        display: flex;
      }
    }
    .participant-info {
      display: flex;
      align-items: center;
      max-width: 100%;
      &.with-button {
        max-width: calc(100% - 40px);
      }
    }
    .participant-avatar {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
      user-select: none;
    }
    .participant-username {
      font-size: 14px;
      line-height: 20px;
      margin: 0 8px;
      width: calc(100% - 48px);
      p, span {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .participant-actions {
      user-select: none;
      display: none;
      align-items: center;
    }
  }
}

.match-tooltip-info {
  background-color: transparent;
  .time-info {
    display: flex;
    align-items: center;
    .clock-icon {
      width: 16px;
      height: 16px;
    }
    .started-at {
      margin: 0 12px 24px 4px;
      font-size: 16px;
      letter-spacing: -0.5px;
    }
    .going-on {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: var(--greenColor);
      font-weight: 700;
    }
  }
  .default_button_wrapper {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .default_button {
    width: 100%!important;
  }
}
