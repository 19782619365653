.tabs-container {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #373947;
  }

  &__capsule {
    column-gap: 10px;

    &:before {
      display: none;
    }
  }
}

.tab-indicator {
  background-color: var(--redColor)!important;

  &__capsule {
    height: 0!important;
  }
}

.tab-wrapper {
  font-family: "Inter", sans-serif!important;
  text-transform: unset!important;
  opacity: 0.5!important;
  font-weight: 400!important;
  min-width: 60px!important;
  max-width: unset!important;

  &:hover {
    opacity: 1!important;
  }

  &__capsule {
    opacity: 1!important;
    font-weight: 600!important;
    font-size: 12px!important;
    border-radius: 100px!important;
    min-height: 32px!important;
    min-width: 47px!important;
  }

  &.tab-selected {
    opacity: 1!important;

    &__capsule {
      background: #F32A2A;
    }
  }
}