.skill-challenges {
  .tabs-wrapper {
    margin-top: 28px;
  }
  &__wrapper {
    background: var(--secondaryBackgroundColor);
    border-radius: 8px;
    padding: 32px;
    position: relative;
  }
  &__connection-list {
    width: 100%;
    margin: -12px 0 32px;
    display: flex;
    flex-flow: column;
  }
  &__connection-user-row {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 0;
    min-height: 64px;
    border-bottom: 1px solid var(--borderColor);
    &.header-row {
      min-height: unset;
    }
    &--avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    &--username {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
    }
    &--is-active {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    &--user-name {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
      opacity: 0.66;
    }
    .table-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    &--col {
      &.header-col {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        user-select: none;
        padding: 12px 0;
        color: var(--grayColor);
        &.active-sort {
          color: var(--primaryColor);
        }
      }
      &:first-of-type {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 30%;
      }
      &:nth-of-type(3) {
        width: 20%;
        display: flex;
        align-items: center;
      }
      &:nth-of-type(4) {
        width: 15%;
      }
      &:last-of-type {
        width: 5%;
      }
    }
    &--status {
      padding: 9px 16px;
      border-radius: 22.5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      &.initial {
        background: rgba(115, 122, 147, 0.1);
        color: var(--grayColor);
      }
      &.error {
        background: var(--errorColorOpacity10);
        color: var(--errorColor);
      }
      &.verifying {
        background: rgba(87, 161, 230, 0.1);
        color: var(--blueColor);
      }
      &.verified {
        background: var(--greenColorOpacity10);
        color: var(--greenColor);
      }
    }
  }
  &__matches-list {
    width: 100%;
    margin: -12px 0 32px;
    display: flex;
    flex-flow: column;
  }
  &__matches-user-row {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 0;
    min-height: 64px;
    &.header-row {
      min-height: unset;
      width: calc(100% - 76px);
    }
    &--avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    &--username {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      display: flex;
      align-items: center;
    }
    &--is-active {
      width: 16px;
      height: 16px;
      margin: 0 4px;
    }
    &--user-name {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.5px;
      opacity: 0.66;
    }
    .table-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    &--col {
      &.header-col {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        user-select: none;
        padding: 12px 0;
        color: var(--grayColor);
        &.active-sort {
          color: var(--primaryColor);
        }
      }
      &:first-of-type {
        width: 33%;
      }
      &:nth-of-type(2) {
        width: 28%;
      }
      &:nth-of-type(3) {
        width: 20%;
        display: flex;
        align-items: center;
      }
      &:last-of-type {
        width: 20%;
      }
    }
    &--status {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      width: max-content;
      color: var(--grayColor);
      &.won, &.lost {
        padding: 9px 24px;
        border-radius: 22.5px;
      }
      &.won {
        background: var(--greenColorOpacity10);
        color: var(--greenColor);
      }
      &.lost {
        background: var(--errorColorOpacity10);
        color: var(--errorColor);
      }
    }
  }
  &__matches-info {
    width: 100%;
    padding: 32px 48px;
    background: var(--additionalTwoBackgroundColor);
    border-radius: 8px;
    &--title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
    &--cols {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 12px;
      width: 100%;
    }
    &--details {
      display: flex;
      align-items: center;
    }
    &--info-wrapper {
      width: 224px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &--label {
      font-size: 14px;
      line-height: 18px;
    }
    &--value {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
    }
    &--separator {
      background: var(--primaryColorOpacity10);
      height: 1px;
      width: 100%;
      margin: 32px 0;
    }
    &--controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .default_button {
        width: 114px!important;
        margin-right: 16px!important;
      }
    }
    &--link {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--blueColor);
    }
  }
  &__statistics-list {
    width: 100%;
    margin: 32px 0;
    display: flex;
    flex-flow: column;
  }
  &__statistics-user-row {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 0;
    min-height: 64px;
    border-bottom: 1px solid var(--borderColor);
    &.header-row {
      min-height: unset;
    }
    &--col {
      width: 12%;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.5px;
      &:first-of-type, &:last-of-type {
        width: 20%;
      }
      &.header-col {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0;
        cursor: pointer;
        user-select: none;
        padding: 12px 0;
        color: var(--grayColor);
        &.active-sort {
          color: var(--primaryColor);
        }
      }
    }
  }
}