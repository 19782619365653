.tournament-branch {
  display: flex;
  flex-flow: column;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: var(--secondaryBackgroundColor);
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #6F7481;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: #6F7481;
  }
  .tournament-stages {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--borderColor );
    padding-bottom: 24px;
    margin-bottom: 16px;
    min-width: 1000px;
    .tournament-stage {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}