.dispute_chat {
    &__body {
        background: transparent;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px -8px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }
    &__chat_messages_wrapper {
        padding: 0 10px 0 0;
        position: relative;
        .preloader_wrapper {
            top: -2px;
        }
        & > div {
            & > div {
                max-height: unset;
            }
        }
    }
}

.chat_panel {
    z-index: 1000;
    position: relative;
    overflow: hidden;
    height: 90px;
    padding: 0 30px 0;
    display: flex;
    align-items: flex-start;
    background: transparent;
    box-shadow: 0px -8px 30px rgba(0, 0, 0, 0.05);
}

.chat_field {
    border: 2px solid #7E869B;
    border-radius: 24px;
    height: 52px;
    width: 100%;
    position: relative;
    input {
        height: 100%;
        padding: 0 90px 0 24px;
        width: 100%;
        background: transparent;
        color: #FFFFFF;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        line-height: 22px;
        &::placeholder {
            color: #FFFFFF;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
        }
        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }
    .chat_send_btn {
        width: 40px;
        height: 40px;
        padding: 0;
        position: absolute;
        right: 6px;
        top: 11px;
    }
}

.chat_messages {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px 0 30px;
    min-height: 630px;
}

.fileInput {
    display: none;
}

.message {
    padding: 11px 13px 5px;
    min-height: 46px;
    max-width: 50%;
    a {
        font-size: 16px;
        line-height: 22px;
        color: #A7DCFF;
        text-decoration: underline;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        &:hover {
            text-decoration: none;
        }
    }
    p {
        font-size: 16px;
        line-height: 18px;
        word-break: break-word;
    }
    span {
        opacity: 0.5;
        font-size: 12px;
        line-height: 18px;
        display: block;
    }
    & > img {
        max-width: 100%;
        max-height: 250px;
        margin: 5px 0;
        cursor: pointer;
        border-radius: 5px;
    }
    button {
        background: transparent;
        margin: 5px 0;
    }
    img {
        max-width: 100%;
        max-height: 250px;
        cursor: pointer;
        border-radius: 5px;
    }
}

.mine_message {
    border-radius: 10px 10px 0 10px;
    background-color: #3A67D0;
    align-self: flex-end;
    margin-bottom: 10px;
    text-align: right;
    p {
        text-align: right;
    }
    span {
        text-align: left;
    }
}

.friend_message {
    background: #19214D;
    border-radius: 10px 10px 10px 0px;
    align-self: flex-start;
    margin-bottom: 10px;
    position: relative;
    margin-left: 31px;
    text-align: left;
    .message_avatar {
        position: absolute;
        bottom: 0;
        left: -31px;
        width: 26px;
        height: 26px;
    }
    p {
        text-align: left;
    }
    span {
        text-align: right;
    }
}

.messages_time_row {
    color: rgba(255,255,255,0.75);
    font-size: 14px;
    line-height: 22px;
    align-self: center;
    margin: 20px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emoji_btn {
    background: transparent;
    position: absolute;
    right: 63px;
    top: calc(50% - 10px);
    cursor: pointer;
    img {
        width: 100%;
    }
}

body {
    .emoji_popover_wrapper {
        margin-top: -12px;
    }
}

div {
    .chat_image_preview {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 100px;
        height: 20px;
        font-family: "Inter", sans-serif;
        font-size: 10px;
        svg {
            font-size: 14px;
        }
    }
}

.chat_field_error {
    font-size: 10px;
    color: #ff344d;
    position: absolute;
    right: 0;
    top: -20px;
}

#lightboxBackdrop {
    & > div {
        & > div {
            & > div {
                &:first-of-type {
                    margin-top: 40px!important;
                }
            }
        }
    }
}

.dispute_resolved {
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--borderColor);
    p {
        font-size: 16px;
        color: #737A93;
    }
}
