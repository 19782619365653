.select_component {
  height: 52px;
  width: 100%;
  .select__control--is-focused {
    box-shadow: none;
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .select__control {
    width: 100%;
    height: 100%;
    background-color: var(--additionalBackgroundColor);
    color: var(--primaryColor)!important;
    font-family: 'Inter', sans-serif!important;
    font-size: 14px;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid rgba(255,255,255,0.35);
    &:before{
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: var(--primaryColor);
      z-index: 1;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -2px;
      right: 51%;
      width: 0;
      height: 2px;
      background-color: var(--primaryColor);
      z-index: 1;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }
    &:hover {
      cursor: pointer;
      border-bottom: 2px solid rgba(255,255,255,0.6);
    }
    .select__placeholder {
      font-family: 'Inter', sans-serif;
      font-weight: 500;
      color: #6B707E;
      letter-spacing: -0.6px;
    }
    .select__single-value {
      margin: 0;
      color: rgba(255, 255, 255, 0.75);
    }
    .select__input {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      input {
        font-size: 14px;
      }
    }
    .select__indicator-separator {
      background: none;
    }
    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #FFFFFF;
        font-size: 14px;
      }
    }
    .select_close_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      svg {
        fill: var(--primaryColor);
        font-size: 14px;
      }
    }
    .select__value-container {
      padding: 0 0 0 16px;
    }
  }

  .select__control--menu-is-open {
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 50%;
      height: 2px;
      background-color: var(--primaryColor);
      z-index: 1;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -2px;
      right: 0;
      width: 51%;
      height: 2px;
      background-color: var(--primaryColor);
      z-index: 1;
    }
  }

  .select__menu {
    z-index: 9999;
    background-color: #22293E;
    margin-top: 0;
    .select__menu-list {
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      color: #9B9B9B;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;

        background: rgba(255,255,255,0.35);
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(255,255,255,0.6);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255,255,255,0.6);
      }
      .select__option{
        background-color: transparent;
        color: rgba(255, 255, 255, 0.75);
        cursor: pointer;
        &:hover{
          color: var(--primaryColor);
          background-color: var(--secondaryBackgroundColor);
        }
      }
      .select__option--is-selected {
        color: var(--primaryColor);
        background-color: var(--secondaryBackgroundColor);
      }

      .status_select{
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent;
        &:hover{
          background: transparent;
        }
      }

    }
  }
}