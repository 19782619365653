.app-panel {
  display: flex;
  background: var(--primaryBackgroundColor);
  box-shadow: 8px 0 32px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 50px);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  &.wide {
    width: 275px;
    min-width: 275px;
  }
  &.hidden {
    width: 104px;
    min-width: 104px;
    nav {
      a {
        position: relative;
        .link-name {
          width: 0;
          margin-left: 0;
        }
        .unread-number {
          position: absolute;
          width: 6px;
          height: 6px;
          top: 16px;
          right: calc(50% - 14px);
          font-size: 0;
        }
      }
    }
  }
  nav {
    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 40px 0;
    width: 100%;
    a {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      height: 58px;
      position: relative;
      padding-left: 40px;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      .link-name {
        overflow: hidden;
        width: max-content;
        white-space: nowrap;
        margin-left: 12px;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: var(--primaryColor);
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -8px;
        top: 0;
        height: 100%;
        width: 8px;
        background: var(--redColor);
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -20px;
        top: 0;
        height: 100%;
        width: 20px;
        opacity: 0.6;
        background: linear-gradient(269.13deg, var(--fieldsBackground) -0.58%, #F04242 182.58%);
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      &:hover {
        background-color: #232841;
      }
      &.active-link {
        background: var(--fieldsBackground);
        &:before {
          left: 0;
        }
        &:after {
          left: 8px;
        }
      }
      .unread-number {
        background: var(--redColor);
        border-radius: 30px;
        font-size: 12px;
        width: 24px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primaryColor);
        font-weight: 700;
        margin-left: 8px;
      }
    }
  }
}
