.default_button_wrapper {
  .default_button {
    text-align: center;
    display: inline-flex;
    white-space: nowrap;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    box-shadow: none;
    &:disabled {
      opacity: 0.5;
      box-shadow: none;
    }
    &.default_button_contained {
      color: var(--primaryColor);
      &.auth-btn {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 12px;
        background: var(--redColor);
        border-radius: 26.7836px;
        border: 1px solid var(--redColor);
        height: 50px;
        width: 150px;
      }
      &.green-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        background: var(--greenColor);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
      &.grey-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        background: var(--primaryColorOpacity10);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
    }
    &.default_button_outlined {
      color: var(--primaryColor);
      border: 1px solid var(--primaryColor);
      &.green-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: var(--greenColor);
        border: 1px solid var(--greenColorOpacity30);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
      &.red-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: var(--errorColor);
        border: 1px solid var(--errorColorOpacity30);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
      &.blue-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: var(--blueColor);
        border: 1px solid rgba(87, 161, 230, 0.5);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
      &.branch-win-btn {
        font-size: 10px;
        font-weight: 700;
        border: 1px solid var(--greenColor);
        border-radius: 20px;
        text-transform: uppercase;
        padding: 2.5px 8px;
        box-sizing: border-box;
        min-width: 36px!important;
      }
      &.grey-btn {
        font-family: "Inter", sans-serif;
        font-weight: 500;
        color: var(--primaryColor);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 40px;
        text-transform: unset;
        width: max-content;
        padding: 8px 24px;
      }
    }
  }
}

.button-text-with-image {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 8px;
  }
}