.page_not_found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: #0E1832;
  min-height: 520px;
  h2 {
    font-size: 72px;
    line-height: 85px;
    text-align: center;
    text-shadow: 0 2px 24px 0 #FFFFFF;
    font-weight: 400;
    margin: 3vh 0 2vh;
  }
  h4 {
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 4vh;
  }
  .auth_button_contained {
    min-width: 235px;
    height: 50px;
    line-height: 50px;
  }
}

.not_found_logo {
  width: 20px;
  margin-bottom: 10vh;
}